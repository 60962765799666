.breadcrumbs {
  background: linear-gradient( 90deg, white, $hilde_main-color 6vw, $hilde_main-color 90vw, white );
  @media (max-width: 1430px) {
    background: linear-gradient( 90deg, $hilde_main-color, $hilde_main-color 3vw, $hilde_main-color 90vw, white );
  }
  @media (max-width: 1340px) {
    background: linear-gradient( 90deg, $hilde_main-color, $hilde_main-color 90vw, white );
  }
  border: none;
  ul.breadcrumb {
    padding: 0.75em 0 !important;
    &>li+li:before {
      color: $nav-breadcrump-text
    }
  }
}

@media (min-width: $screen-desktop-min) {
  .head-container {
    .bis-logo .ap-image {
      max-width: 180px;
      margin-top: -2rem;
    }
    .bis-slider {
      .text {
        margin-top: 2em;
      }
    }
    .bis-site,
    .bis-slider {
      .text {
        h1 {
          color: $main-theme;
          margin-bottom: 0.25em;
        }
        h2 {
          color: $hilde_main-color;
        }
      }
    }
    .container {
      &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 999;
        width: 35px;
        height: 100vh;
        margin-left: -55px;
        background: linear-gradient( 0deg, white, $hilde_main-color 10%, $hilde_main-color 90%, white );
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  .bis-site {
    margin-top: 0 !important;
  }
}
